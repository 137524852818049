// 
// nav.scss
//

.nav-tabs,.nav-pills {
    > li {
        > a {
            color: $gray-700;
            font-weight: $font-weight-medium;
        }
    }
}

.nav-tabs .nav-link,
.nav-item .nav-link {
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: #ddd;

    &:hover {
        cursor: pointer;
        opacity: 1;
        border-width: 1px 1px 0 1px;
        border-style: solid;
        border-color: #ccc;
        background-color: #00FFD950;
        // transition: opacity .75s ease-in-out;
        // -moz-transition: opacity .75s ease-in-out;
        // -webkit-transition: opacity .75s ease-in-out;
        // transition: background-color .75s ease-in-out;
        // -moz-transition: background-color .75s ease-in-out;
        // -webkit-transition: background-color .75s ease-in-out;
    }
}

div > li:hover > .nav-tabs .nav-link,
div > li:hover > .nav .nav-item .nav-link {
    // opacity: 0.6;
    background-color: #fff;
}

.nav-pills {
    > a {
        color: $gray-700;
        font-weight: $font-weight-medium;   
    }
}

.tab-content {
    padding: 20px;
    border: 2px solid $gray-200;
    border-top: none;
}  
