// 
// components-demo.scss
//

// Demo Only
.button-list {
    .btn {
        margin-top: 7px;
        margin-right: 5px;
    }
}

// list group

.list-group-item {
    border: 1px solid $gray-100;
    border-left: none;
    border-right: none;

    &:hover {
        background: $gray-100;
    }

    &:first-child {
        border-top: none;
    }

    &:last-child {
        border-bottom: none;
    }
}

// Icon demo ( Demo only )
.icons-list-demo {
    div {
        cursor: pointer;
        line-height: 45px;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;

        p {
            margin-bottom: 0;
            line-height: inherit;
        }
    }

    i {
        text-align: center;
        vertical-align: middle;
        font-size: 22px;
        width: 50px;
        height: 50px;
        line-height: 50px;
        margin-right: 12px;
        color: rgba($dark, 0.7);
        border-radius: 3px;
        display: inline-block;
        transition: all 0.2s;
    }

    .col-lg-4 {
        -webkit-border-radius: 3px;
        border-radius: 3px;
        -moz-border-radius: 3px;
        background-clip: padding-box;
        margin-bottom: 10px;

        &:hover,
        &:hover i {
            color: $primary;
        }
    }
}


// Scrollspy

.scrollspy-example {
    position: relative;
    height: 200px;
    margin-top: .5rem;
    overflow: auto;
}

// stepper

.stepper {
    overflow: hidden;
    /*CSS counters to number the steps*/
    counter-reset: step;
    padding: 0;


    li {
        list-style-type: none;
        color: white;
        text-transform: uppercase;
        font-size: 9px;
        width: 33.33%;
        float: left;
        position: relative;
        letter-spacing: 1px;

        &:before {
            content: counter(step);
            counter-increment: step;
            width: 32px;
            height: 32px;
            line-height: 34px;
            display: block;
            font-size: 16px;
            text-align: center;
            font-weight: $font-weight-bold;
            color: $white;
            background: $gray-400;
            border-radius: 100%;
            margin: 0 auto 10px auto;
            z-index: 10;
            position: relative;
        }

        &:after {
            content: '';
            width: 100%;
            height: 2px;
            background: $gray-400;
            position: absolute;
            left: -50%;
            top: 16px;
            z-index: 0;

        }

        &:first-child:after {
            /*connector not needed before the first step*/
            content: none;
        }

        &.active {

            &:before,
            &:after {
                background: $primary;
                color: white;
            }
        }
    }
}
