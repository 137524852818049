
// 
// breadcrumb.scss
//
.breadcrumb {
    padding-top: 0px;
    // font-size: 1.1rem;
    margin: 0px;
    font-size: 0.8rem;
}

@media (min-width: 768px) and (max-width: 991px) {
    .breadcrumb {
        font-size: 1.1rem;
    }
}

@media (min-width: 992px) {
    .breadcrumb {
        font-size: 1.25rem;
    }
}

// Breadcrumb item arrow
.breadcrumb-item {
    color: #666;
    
    // > a {
    //     color: $gray-700;
    // }
    +.breadcrumb-item {
        padding-left: 3px;
        &::before {
            padding-right: 3px;
            font-family: "Material Design Icons";
        }
    }
}
  