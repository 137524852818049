// 
// tables.scss
//

table {
    text-align: left;

    td,th {
        vertical-align: middle !important;

        @media (min-width: 0px) and (max-width: 575.98px) {
            // Default padding is 0.85rem, so we'll half it
            // on smaller screen sizes so the table fits better
            padding: 0.425rem !important;
        }
    }
}

//Table centered (Custom)
.table-centered {
    td,th {
        vertical-align: middle !important;
    }
}

// Allow sufficient height in the table so that if a column has a selection box value
// at least two of the list items appear so the user knows that there are more values
// that they may be able to scroll down to view

// UPDATE:: Commented on Feb 25 by Nikhil - this is messing up the overall scroll, we do not need to set height for the above described issue
// .react-bootstrap-table.table-responsive {
//     height: 100%;
// }

// This is required to for the horizontal scrollbar to appear
// when some columns do not fit in the screen on smaller devices
.card {
    overflow-x: auto !important;
}

// Override the default bottom border
.MuiTable-root tr td {
    border-bottom: 1px solid #dee2e6;
}
