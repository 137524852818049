//
// Select2.scss
//

.select2-container {
    width: 100% !important;
    .select2-selection--single {
        border: 1px solid $input-border-color;
        height: 38px;
        outline: none;
        .select2-selection__rendered {
            line-height: 36px;
            padding-left: 12px;
        }
        .select2-selection__arrow {
            height: 34px;
            width: 34px;
            right: 3px;
            b {
                border-color: darken($light, 15%) transparent transparent transparent;
                border-width: 6px 6px 0 6px;
            }
        }
    }
}

.select2-container--open {
    .select2-selection--single {
        .select2-selection__arrow {
            b {
                border-color: transparent transparent darken($light, 15%) transparent !important;
                border-width: 0 6px 6px 6px !important;
            }
        }
    }
}

.select2-results__option {
    padding: 6px 12px;
    .select2-results__group{
        color: $dark;
    }
}

.select2-dropdown {
    border: 1px solid darken($light, 5%);
    box-shadow: $shadow;
}

.select2-container--default {
    .select2-search--dropdown {
        padding: 10px;
        background-color: lighten($light, 5%);
        .select2-search__field {
            border: 1px solid darken($light, 5%);
            outline: none;
        }
    }
    .select2-results__option--highlighted[aria-selected] {
        background-color: $primary;
    }
    .select2-results__option[aria-selected=true] {
        background-color: $light;
        color: $dark;
        &:hover {
            background-color: $primary;
            color: $white;
        }
    }
}

.select2-container {
    .select2-selection--multiple {
        min-height: 38px;
        border: 1px solid $input-border-color !important;
        .select2-selection__rendered {
            padding: 1px 10px;
        }
        .select2-search__field {
            border: 0;
        }
        .select2-selection__choice {
            background-color: $primary;
            border: none;
            color: $white;
            border-radius: 3px;
            padding: 0 7px;
            margin-top: 7px;
        }
        .select2-selection__choice__remove {
            color: $white;
            margin-right: 5px;
            &:hover {
                color: $white;
            }
        }
    }
}

//
// react select
//

.react-select {
    > div {
        border: $input-border-width solid $input-border-color;

        &:hover {
            border: $input-border-width solid $input-border-color;
        }
    }
}

.rbt-token {
    background-color: rgba($primary,0.2) !important;
    color: $primary !important;
}

.rbt-input-multi.focus {
    box-shadow: none !important;
    background-color: $input-focus-bg !important;
    border-color: $input-focus-border-color !important;
}

.react-select__control--is-focused {
    border-color: $input-focus-border-color !important;
    box-shadow: none !important;
}

.react-select__indicator-separator {
    display: none;
}

.react-select__dropdown-indicator {
    color: $gray-600;
}

.react-select__menu {
    background-color: $dropdown-bg !important;
    box-shadow: none !important;
    border: none !important;
    border-radius: $dropdown-border-radius !important;
    z-index: $zindex-dropdown !important;
}

.react-select__menu-list {
    border: $dropdown-border-width solid $dropdown-border-color !important;
    border-radius: $dropdown-border-radius !important
}

.react-select__option {
    padding: $dropdown-item-padding-y $dropdown-item-padding-x !important;
    color: $dropdown-link-color !important;

    &:hover {
        color: $dropdown-link-hover-color !important;
        background-color: $dropdown-link-hover-bg !important;
    }
}

.react-select__option--is-focused,
.react-select__option--is-selected {
    color: $dropdown-link-hover-color !important;
    background-color: $dropdown-link-hover-bg !important;
}

.react-select__multi-value {
    background-color: rgba($primary,0.2) !important;
    color: $primary !important;

    .react-select__multi-value__label {
        color: $primary;
    }

    .react-select__multi-value__remove {
        &:hover {
            background-color: rgba($primary,0.2) !important;
            color: $primary !important;
        }
    }
}

.is-invalid {
    .react-select-validation__control {
        border: $input-border-width solid $danger;
    }
}

