// 
// authentication.scss
//

.authentication-bg.enlarged {
    min-height: 100px;
}

// authentication pages background
body.authentication-bg {
    background-image: url("../images/big/bg1.jpg");
    background-size: cover;
    background-position: center;
}

.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}

// Logout page
.logout-icon {
    width: 140px;
}

// new styles
.map-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
}

.login-form-container {
    position: absolute;
    background: rgba(233, 238, 239, 0.75);
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    width: 430px;

    @media (max-width: 767.98px) {
        width: 100%;
    }

    .logo {
        height: 54px;
    }
}

.back-to-login-btn {
    z-index: 1000;
    position: absolute;
    bottom: 30px;
    right: 30px;
}

// registration
.phrase-item {
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center !important;

    .no {
        font-weight: $font-weight-bold;
        width: 25%;
        text-align: right;
    }
    
    .word {
        padding: 0.70rem;
        width: 75%;
        display: inline-block;
        margin-left: 6px;
        border: 1px solid #dee2e6 !important;
    }
}
