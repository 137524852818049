$base-color: #323a46;
$font-color: #323a46;
$bg-color: #00FFD950;

.select-custom {
  background: {
    color: $bg-color;
    image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1255/caret--down-15.png");
    position: right;
    repeat: no-repeat;
  };
  border: {
    color: mix($bg-color, $base-color, 90%);
    radius: .4em;
    style: solid;
    width: 1px;
    right-color: mix($bg-color, $base-color, 80%);
    bottom-color: mix($bg-color, $base-color, 80%);
  }
  color: $font-color;
  font-size: 16px;
  padding: .33em .5em;
  width: 100%;
  /* Remove default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select-custom::-ms-expand {
  // Removes default arrow for Internet Explorer 10 (and later)
  // Internet Explorer 8/9 gets the default arrow which covers the caret
  // image as long as the caret image is smaller than and positioned
  // behind the default arrow
  display: none;
}

/* Target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width:0\0) {
  .select-custom {
    background: none\9;
    padding: 5px\9;
  }
}
