@keyframes animationName {
  0% {
    background: rgba(0,50,150,.8);
  }
  50% {
    background: rgba(0,50,150,1);
  }
  100% {
    background: rgba(0,50,150,.8);
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  /* animation: animationName 2s infinite; */
}

.default-text {
  color: white !important;
  font-family: 'Montserrat' !important;
}
.nav-text {
  font-size: 14 ;
}
.title-bar {
  height: 115;
  width: '50%';
}
.space-between {
  display: flex;
  justify-content:space-between;
  padding-bottom: 0 !important;
}
.bgcolorPaper {
  background-color: red !important;
}
.flex {
  display: flex;
  flex-direction: column !important;
}
.fix-padding {
  margin-left: -125px;
}

.iconStyle {
  max-height: 20px;
  background-size: contain;
}

.arrow{
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;

}
.arrow .left{
  display: block;
  width: 30px;
  height: 30px;
  border-bottom: 5px solid #06A8FF;
  border-right: 5px solid #06A8FF;
  transform: rotate(45deg);
  margin: -5px;
  animation: animate 2s infinite;
}
.arrow .left:nth-child(2){
  animation-delay: -0.2s;
}
.arrow .left:nth-child(3){
  animation-delay: -0.4s;
}
@keyframes animate {
  0%{
      opacity: 0;
      transform: rotate(135deg) translate(-20px,-20px);
  }
  50%{
      opacity: 1;
  }
  100%{
      opacity: 0;
      transform: rotate(135deg) translate(20px,20px);
  }
}

.base {
  background: #70b6f9;
  display: inline-block;
  height: 30px;
  margin-left: 20px;
  margin-right: 10px;
  margin-top: 0px;
  position: relative;
  width: 100px;
  border-radius: 15 10 15 10;
}
.base:before {
  border-bottom: 15px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid #70b6f9;
    border-top: 15px solid transparent;
  content: "";
  height: 0;
  right: 100px;
  position: absolute;
  top: 0px;
  width: 0;
}

.baseRight {
  background: #70b6f9;
  display: inline-block;
  height: 30px;
  margin-left: 10px;
  margin-top: 0px;
  position: relative;
  width: 100px;
}
.baseRight:before {
  border-bottom: 15px solid transparent;
  border-left: 10px solid #70b6f9;
  border-right: 10px solid transparent;
  border-top: 15px solid transparent;
  content: "";
  height: 0;
  left: 100px;
  position: absolute;
  top: 0px;
  width: 0;
}